<template>
  <DefaultLayout no-lang>
    <FloatingBox class="sm:w-auto">
      <span v-if="failed" class="text-red-700">{{$t('qr.failed')}}</span>
      <div v-else class="text-center">
        <div v-if="collected" class="mb-4 text-xl text-red-700 font-bold">{{$t('barcode.already-collected')}}</div>
        <div v-else-if="location" class="mb-4 text-xl">{{$t('barcode.collect-at')}} <strong>{{location}}</strong></div>

        <img :src="url" class="qr mx-auto" @error="error">
      </div>
    </FloatingBox>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/Default.vue'
import FloatingBox from '../components/FloatingBox'
import api from '../api';

export default {
  name: 'Search',
  components: {
    DefaultLayout,
    FloatingBox,
  },
  props: {
    barcode: String,
    hash: String
  },
  data() {
    return {
      failed: false,
      collected: false,
      location: null,
    }
  },
  computed: {
    url() {
        return process.env.VUE_APP_API_HOST + `qr/${this.barcode}/${this.hash}`
    }
  },
  async created() {
      const {data: {collected, location}} = await api.get(`barcode-info/${this.$route.params.barcode}`)
      this.collected = collected
      this.location = location
  },
  methods: {
    error() {
      this.failed = true
    }
  },
  metaInfo() {
    return {
      title: this.$t('qr.title'),
    }
  } 
};
</script>

<style lang="scss" scoped>
  .qr {
    width: 100%;
  }
</style>